import axios from "axios"

import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  LOGOUT_LOADING,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
} from "./types"
import { BASE_URL } from "../../utils/urls"

// Check Load User
export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: GET_USER_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/user", tokenConfig(getState))
      .then(response => {
        dispatch({ type: GET_USER_SUCCESS, payload: response.data })
        resolve(response.data)
      })
      .catch(error => {
        dispatch({
          type: GET_USER_FAIL,
          payload: error.response ? error.response.data : error,
        })
        reject(error.response ? error.response.data : error)
      })
  })
}

// UPDATE USER
export const updateUser = ({ name, email, phoneNumber, profilePicture }) => (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_USER_LOADING })
  return new Promise((resolve, reject) => {
    let body = new FormData()
    body.append("name", name)
    body.append("email", email)
    body.append("phone_numbers", phoneNumber)
    body.append("profile_picture", profilePicture ? profilePicture : "")
    axios
      .post(BASE_URL + "/api/update-user", body, tokenConfig(getState))
      .then(response => {
        dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data.user })
        resolve(response.data)
      })
      .catch(error => {
        dispatch({
          type: UPDATE_USER_FAIL,
          payload: error.response ? error.response.data : error,
        })
        reject(error.response ? error.response.data : error)
      })
  })
}

// LOGOUT
export const logout = () => (dispatch, getState) => {
  dispatch({ type: LOGOUT_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + "/api/logout", null, tokenConfig(getState))
      .then(response => {
        dispatch({ type: LOGOUT_SUCCESS, payload: response.data })
        resolve(response.data)
      })
      .catch(error => {
        dispatch({
          type: LOGOUT_FAIL,
          payload: error.response ? error.response.data : error,
        })
        reject(error.response ? error.response.data : error)
      })
  })
}

// Login User
export const login = ({ email, password }) => dispatch => {
  dispatch({ type: LOGIN_LOADING })
  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    let credentials = {
      email: email,
      password: password,
      device_name: "WEB_APP",
    }
    const body = JSON.stringify(credentials)
    axios
      .post(BASE_URL + "/api/login", body, config)
      .then(res => {
        dispatch({
          type: res.data.success === true ? LOGIN_SUCCESS : LOGIN_FAIL,
          payload: res.data,
        })
        resolve(res.data)
      })
      .catch(err => {
        dispatch({
          type: LOGIN_FAIL,
          payload: err.response ? err.response.data : err,
        })
        reject(err)
      })
  })
}

// Register User
export const register = ({
  name,
  phoneNumber,
  email,
  password,
}) => dispatch => {
  dispatch({ type: REGISTER_LOADING })
  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    const body = JSON.stringify({
      name,
      email,
      phone_numbers: phoneNumber,
      password,
      device_name: "WEB_APP",
    })
    axios
      .post(BASE_URL + "/api/register", body, config)
      .then(res => {
        if (res.data.success === true) {
          dispatch({ type: REGISTER_SUCCESS, payload: res.data })
          resolve(res.data)
        } else {
          dispatch({ type: REGISTER_FAIL, payload: res.data })
          reject(res.data)
        }
      })
      .catch(err => {
        console.log(err.response.data.errors)
        dispatch({
          type: REGISTER_FAIL,
          payload: err.response ? err.response.data : err,
        })
        reject(err.response ? err.response.data : err)
      })
  })
}

export const tokenConfig = getState => {
  // Get token from localStorage
  const token = getState().auth.token

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
  if (token) {
    config.headers["Authorization"] = "Bearer " + token
  }

  return config
}
