import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { connect } from "react-redux"
import { logout } from "../store/actions/authActions"
import { getCategories } from "../store/actions/categoryActions"

const Header = ({ cartProducts, user, logout, getCategories, categories }) => {
  const [color, setColor] = useState("bg-transparent")
  const [textColor, setTextColor] = useState("text-gray-700 md:text-white")
  const [isOpen, setIsOpen] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [showMenuTC, setShowMenuTC] = useState(false)

  useEffect(() => {
    if (categories.length === 0) {
      getCategories()
    }
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", listenScrollEvent)
    }
  }, [])

  const listenScrollEvent = e => {
    if (typeof window !== `undefined`) {
      if (window.scrollY > 50) {
        setColor("bg-white shadow-lg")
        setTextColor("text-gray-700 hover:text-gray-900")
      } else {
        setColor("bg-transparent")
        setTextColor("text-gray-700 md:text-white")
      }
    }
  }

  const handleLogout = () => {
    logout()
      .then(() => {
        navigate("/")
      })
      .catch(() => {
        console.log("Logout error")
      })
  }

  const redirectToUserPage = page => {
    navigate("/user" + page)
    setShowMenuTC(!showMenu)
  }

  return (
    <div className={"fixed top-0 left-0 right-0 " + color} style={{}}>
      <div className="container mx-auto">
        <nav className="flex items-center justify-between flex-wrap px-3 pt-3">
          <div className="flex items-center flex-shrink-0 mr-6">
            <Link to="/">
              <img
                src={require("../images/logo-new.png")}
                style={{ height: "50px" }}
                alt=""
                srcSet=""
              />
            </Link>
          </div>
          <div className="block lg:hidden flex flex-wrap">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="flex items-center px-3 py-2 border rounded text-blue-600 border-blue-600 hover:text-blue-800 hover:border-blue-800"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
            <Link
              to="/cart"
              className="bg-black hover:bg-black text-white font-bold py-1 px-3 rounded-full mx-2 text-xs"
            >
              {cartProducts.length}
            </Link>
          </div>
          <div
            className={
              isOpen
                ? "w-full block flex-grow lg:flex lg:items-center lg:w-auto pb-4 md:pb-0 bg-white pl-4 md:bg-transparent md:pl-0 rounded-lg"
                : "w-full hidden flex-grow lg:flex lg:items-center lg:w-auto"
            }
          >
            <div className="text-sm lg:flex-grow flex justify-center flex-col md:flex-row">
              <Link
                to="/"
                className={
                  "block mt-4 text-xs lg:inline-block lg:mt-0 " +
                  textColor +
                  " mr-4 font-bold"
                }
              >
                Accueil
              </Link>
              <div>
                <a
                  className={
                    "block mt-4 text-xs lg:inline-block lg:mt-0 mr-4 font-bold " +
                    textColor
                  }
                  href="#"
                  onClick={() => setShowMenuTC(!showMenuTC)}
                >
                  Tarifs et commande
                </a>
                {showMenuTC && (
                  <div className="rounded shadow absolute px-3 bg-white">
                    <div className="py-2 border-b text-xs">
                      <Link
                        onClick={() => {
                          setShowMenuTC(!showMenuTC)
                        }}
                        to="/all-clothes"
                        className="hover:bg-grey-light"
                      >
                        Tous nos tarifs
                      </Link>
                    </div>
                    <div className="py-2 border-b text-xs">
                      <a
                        href="#"
                        onClick={() => {
                          navigate("/man-clothes")
                          setShowMenuTC(!showMenuTC)
                        }}
                        className="hover:bg-grey-light"
                      >
                        Vêtements hommes
                      </a>
                    </div>
                    <div className="py-2 border-b text-xs">
                      <a
                        href="#"
                        onClick={() => {
                          navigate("/woman-clothes")
                          setShowMenuTC(!showMenuTC)
                        }}
                        className="hover:bg-grey-light"
                      >
                        Vêtements femmes
                      </a>
                    </div>
                    <div className="py-2 border-b text-xs">
                      <a
                        href="#"
                        onClick={() => {
                          navigate("/bedding-clothes")
                          setShowMenuTC(!showMenuTC)
                        }}
                        className="hover:bg-grey-light"
                      >
                        Literie, Rideau et Peluche
                      </a>
                    </div>
                    <div className="py-2 border-b text-xs">
                      <a
                        href="#"
                        onClick={() => {
                          navigate("/carpet-clothes")
                          setShowMenuTC(!showMenuTC)
                        }}
                        className="hover:bg-grey-light"
                      >
                        Moquettes & Tapis
                      </a>
                    </div>
                    <div className="py-2 border-b text-xs">
                      <a
                        href="#"
                        onClick={() => {
                          navigate("/was-chair-clothes")
                          setShowMenuTC(!showMenuTC)
                        }}
                        className="hover:bg-grey-light"
                      >
                        Tais de fauteuil et Pagne
                      </a>
                    </div>
                    <div className="py-2 border-b text-xs">
                      <a
                        href="#"
                        onClick={() => {
                          navigate("/company-clothes")
                          setShowMenuTC(!showMenuTC)
                        }}
                        className="hover:bg-grey-light text-green-600 font-bold"
                      >
                        Offre entreprise
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <Link
                to="/about-us"
                className={
                  "block mt-4 text-xs lg:inline-block lg:mt-0 " +
                  textColor +
                  " mr-4 font-bold"
                }
              >
                A propos de nous
              </Link>
              <Link
                to="/posts/list"
                className={
                  "block mt-4 text-xs lg:inline-block lg:mt-0 " +
                  textColor +
                  " mr-4 font-bold"
                }
              >
                Blog
              </Link>
              <Link
                to="/contact-us"
                className={
                  "block mt-4 text-xs lg:inline-block lg:mt-0 " +
                  textColor +
                  " mr-4 font-bold"
                }
              >
                Contactez-nous
              </Link>
            </div>
            {user ? (
              <div className="md:border-l md:border-gray-400">
                <div className="flex flex-col text-xs">
                  <div className="pt-4 md:pt-0">
                    <a
                      className={"ml-0 md:ml-4 mr-2 font-bold " + textColor}
                      href="#"
                      onClick={() => setShowMenu(!showMenu)}
                    >
                      {user.name}
                    </a>
                    <Link
                      to="/cart"
                      className="bg-black hover:bg-black text-white font-bold py-1 px-3 rounded-full mx-2"
                    >
                      {cartProducts.length}
                    </Link>
                  </div>
                  {showMenu && (
                    <div className="rounded shadow absolute mt-10 px-3 w-40 bg-white">
                      <div className="py-2">
                        <a
                          to="#"
                          onClick={() => redirectToUserPage("/orders")}
                          className="hover:bg-grey-light font-semibold"
                        >
                          Mes commandes
                        </a>
                      </div>
                      <div className="border-t py-2">
                        <a
                          to="#"
                          onClick={() => redirectToUserPage("/profile")}
                          className="hover:bg-grey-light font-semibold"
                        >
                          Mon compte
                        </a>
                      </div>
                      <div className="border-t py-2">
                        <button
                          onClick={handleLogout}
                          className="rounded-full w-full bg-green-500 hover:bg-green-700 text-white py-1 font-semibold"
                        >
                          Déconnexion
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="md:border-l md:border-gray-400 pt-3 md:pt-0">
                <Link
                  to="/login"
                  className={"md:ml-4 mr-2 text-xs font-bold " + textColor}
                >
                  Connexion
                </Link>
                <Link
                  to="/register"
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-3 rounded mx-2 text-xs"
                >
                  Inscription
                </Link>
                <Link
                  to="/cart"
                  className="bg-black hover:bg-black text-white font-bold py-1 px-3 rounded-full mx-2 text-xs"
                >
                  {cartProducts.length}
                </Link>
              </div>
            )}
          </div>
        </nav>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    cartProducts: state.product.cart,
    user: state.auth.user,
    categories: state.category.categories,
  }
}

const mapDispatchToProps = {
  logout,
  getCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
