import axios from "axios"

import {
  GET_PRODUCTS_LOADING,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_FIRST_PRODUCTS_LOADING,
  GET_FIRST_PRODUCTS_SUCCESS,
  GET_FIRST_PRODUCTS_FAIL,
  GET_PRODUCTS_BY_CATEGORY_LOADING,
  GET_PRODUCTS_BY_CATEGORY_SUCCESS,
  GET_PRODUCTS_BY_CATEGORY_FAIL,
  ADD_TO_CART,
  REMOVE_TO_CART,
  INCREMENT_CART_PRODUCT,
  DECREMENT_CART_PRODUCT,
  EMPTY_CART,
} from "./types"
import { BASE_URL } from "../../utils/urls"

export const getProducts = () => (dispatch, getState) => {
  dispatch({ type: GET_PRODUCTS_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/products")
      .then(response => {
        let _products = response.data.map(product => {
          let _categories =
            product.categories.length > 0
              ? product.categories.map(category => category.id)
              : []
          return {
            id: product.id,
            name: product.name,
            selling_price: product.selling_price,
            cover: product.cover,
            categories: _categories,
          }
        })
        resolve(dispatch({ type: GET_PRODUCTS_SUCCESS, payload: _products }))
      })
      .catch(error => {
        dispatch({ type: GET_PRODUCTS_FAIL })
        if (error.response) {
          console.log(error.response.data)
        } else {
          console.log(error)
        }
        reject(error.response.data)
      })
  })
}

export const getFirstProducts = () => (dispatch, getState) => {
  dispatch({ type: GET_FIRST_PRODUCTS_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/products?productCount=4")
      .then(response => {
        resolve(
          dispatch({ type: GET_FIRST_PRODUCTS_SUCCESS, payload: response.data })
        )
      })
      .catch(error => {
        dispatch({ type: GET_FIRST_PRODUCTS_FAIL })
        if (error.response) {
          console.log(error.response.data)
        } else {
          console.log(error)
        }
        reject(error.response.data)
      })
  })
}

export const getProductsByCategory = categoryId => (dispatch, getState) => {
  dispatch({ type: GET_PRODUCTS_BY_CATEGORY_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/products-by-category/" + categoryId)
      .then(response => {
        resolve(
          dispatch({
            type: GET_PRODUCTS_BY_CATEGORY_SUCCESS,
            payload: response.data,
          })
        )
      })
      .catch(error => {
        dispatch({ type: GET_PRODUCTS_BY_CATEGORY_FAIL })
        if (error.response) {
          console.log(error.response.data)
        } else {
          console.log(error)
        }
        reject(error.response.data)
      })
  })
}

export const addToCart = productId => (dispatch, getState) => {
  dispatch({ type: ADD_TO_CART, payload: productId })
}

export const removeToCart = productId => (dispatch, getState) => {
  dispatch({ type: REMOVE_TO_CART, payload: productId })
}

export const incrementCartProduct = productId => (dispatch, getState) => {
  dispatch({ type: INCREMENT_CART_PRODUCT, payload: productId })
}

export const decrementCartProduct = productId => (dispatch, getState) => {
  dispatch({ type: DECREMENT_CART_PRODUCT, payload: productId })
}

export const emptyCart = () => (dispatch, getState) => {
  dispatch({ type: EMPTY_CART })
}

export const tokenConfig = getState => {
  // Get token from localStorage
  const token = getState().auth.token

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
  if (token) {
    config.headers["Authorization"] = "Bearer " + token
  }

  return config
}
