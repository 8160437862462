import React, { useEffect } from "react"
import { connect } from "react-redux"

import { getFirstProducts } from "../store/actions/productActions"

const Footer = ({ isLoading, firstProducts, getFirstProducts }) => {
  useEffect(() => {
    if (firstProducts.length === 0) {
      getFirstProducts()
    }
  }, [])
  return (
    <footer>
      <div className="bg-blue-900">
        <div className="container mx-auto px-5">
          <div className="flex flex-wrap mb-4 mt-12 pt-8">
            <div className="w-full sm:w-full md:w-1/4 py-2">
              <h3 className="text-xl text-gray-600">A propos de nous</h3>
              <p className="text-xs text-gray-400">
                Nous sommes un service de pressing en ligne. <br />
                <span className="font-bold">Notre objectif :</span> <br />
                1- Vous faire gagner du temps
                <br />
                2- Vous offrir des vêtements propres bien LAVÉS à des prix très
                concurrentiels.
              </p>
            </div>
            <div className="w-full sm:w-full md:w-1/4 py-2">
              <h3 className="text-xl text-gray-600">Nos Services</h3>
              <p className="text-xs text-gray-400">
                <li>Laver</li>
                <li>Sécher</li>
                <li>Répasser</li>
                <li>Livrer</li>
              </p>
            </div>
            <div className="w-full sm:w-full md:w-1/4 py-2">
              <h3 className="text-xl text-gray-600">Nos vêtements</h3>
              <p className="text-xs text-gray-400">
                {isLoading === false &&
                  firstProducts.map(product => (
                    <li key={product.id}>{product.name}</li>
                  ))}
              </p>
            </div>
            <div className="w-full sm:w-full md:w-1/4 py-2">
              <h3 className="text-xl text-gray-600">Contactez-nous</h3>
              <p className="text-xs text-gray-400">
                <li>Email: monepressing@gmail.com</li>
                <li>N° de téléphone: +225 07 58 42 05 59</li>
                <li>Adresse: Abidjan, Côte d'ivoire</li>
              </p>
              <div className="flex">
                <a
                  href="https://www.facebook.com/monepressing"
                  target="_blank"
                  className="mx-1"
                >
                  <svg
                    className="text-gray-400 h-8 w-8 fill-current text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M17 1H3c-1.1 0-2 .9-2 2v14c0 1.101.9 2 2 2h7v-7H8V9.525h2v-2.05c0-2.164 1.212-3.684 3.766-3.684l1.803.002v2.605h-1.197c-.994 0-1.372.746-1.372 1.438v1.69h2.568L15 12h-2v7h4c1.1 0 2-.899 2-2V3c0-1.1-.9-2-2-2z" />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/explore/tags/monepressing/"
                  target="_blank"
                  className="mx-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    className="text-gray-400 h-8 w-8 fill-current text-gray-400"
                  >
                    <path d="M17.946 5.29a6.606 6.606 0 0 0-.418-2.185 4.412 4.412 0 0 0-1.039-1.594A4.412 4.412 0 0 0 14.895.472a6.606 6.606 0 0 0-2.184-.418C11.75.01 11.444 0 9 0S6.25.01 5.29.054a6.606 6.606 0 0 0-2.185.418A4.412 4.412 0 0 0 1.51 1.511 4.412 4.412 0 0 0 .472 3.105a6.606 6.606 0 0 0-.418 2.184C.01 6.25 0 6.556 0 9s.01 2.75.054 3.71a6.606 6.606 0 0 0 .418 2.185 4.412 4.412 0 0 0 1.039 1.594 4.411 4.411 0 0 0 1.594 1.039 6.606 6.606 0 0 0 2.184.418C6.25 17.99 6.556 18 9 18s2.75-.01 3.71-.054a6.606 6.606 0 0 0 2.185-.418 4.602 4.602 0 0 0 2.633-2.633 6.606 6.606 0 0 0 .418-2.184C17.99 11.75 18 11.444 18 9s-.01-2.75-.054-3.71zm-1.62 7.347a4.978 4.978 0 0 1-.31 1.67 2.98 2.98 0 0 1-1.708 1.709 4.979 4.979 0 0 1-1.671.31c-.95.043-1.234.052-3.637.052s-2.688-.009-3.637-.052a4.979 4.979 0 0 1-1.67-.31 2.788 2.788 0 0 1-1.036-.673 2.788 2.788 0 0 1-.673-1.035 4.978 4.978 0 0 1-.31-1.671c-.043-.95-.052-1.234-.052-3.637s.009-2.688.052-3.637a4.979 4.979 0 0 1 .31-1.67 2.788 2.788 0 0 1 .673-1.036 2.788 2.788 0 0 1 1.035-.673 4.979 4.979 0 0 1 1.671-.31c.95-.043 1.234-.052 3.637-.052s2.688.009 3.637.052a4.979 4.979 0 0 1 1.67.31 2.788 2.788 0 0 1 1.036.673 2.788 2.788 0 0 1 .673 1.035 4.979 4.979 0 0 1 .31 1.671c.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637zM9 4.378A4.622 4.622 0 1 0 13.622 9 4.622 4.622 0 0 0 9 4.378zM9 12a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm5.884-7.804a1.08 1.08 0 1 1-1.08-1.08 1.08 1.08 0 0 1 1.08 1.08z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-5 pb-2">
          <div className="flex">
            <div className="flex-1 text-left text-xs text-gray-400">
              © {new Date().getFullYear()} Mon E-Pressing, tous droits réservés
            </div>
            <div className="flex-1 text-right text-xs text-gray-400">
              Make with ❤️ by{" "}
              <a target="_blank" href="https://theitduck.github.io">the_itduck</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

const mapStateToProps = state => ({
  isLoading: state.product.isLoading,
  firstProducts: state.product.firstProducts,
})

const mapDispatchToProps = {
  getFirstProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
