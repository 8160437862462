import axios from "axios"

import {
  GET_CATEGORIES_LOADING,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORY_LOADING,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
} from "./types"
import { BASE_URL } from "../../utils/urls"

export const getCategories = () => (dispatch, getState) => {
  dispatch({ type: GET_CATEGORIES_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/categories", tokenConfig(getState))
      .then(response => {
        resolve(
          dispatch({ type: GET_CATEGORIES_SUCCESS, payload: response.data })
        )
      })
      .catch(error => {
        dispatch({ type: GET_CATEGORIES_FAIL })
        if (error.response) {
          console.log(error.response.data)
        } else {
          console.log(error)
        }
        reject(error.response.data)
      })
  })
}

export const getCategory = categoryId => (dispatch, getState) => {
  dispatch({ type: GET_CATEGORY_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/categories/" + categoryId, tokenConfig(getState))
      .then(response => {
        resolve(
          dispatch({ type: GET_CATEGORY_SUCCESS, payload: response.data })
        )
      })
      .catch(error => {
        dispatch({ type: GET_CATEGORY_FAIL })
        if (error.response) {
          console.log(error.response.data)
        } else {
          console.log(error)
        }
        reject(error.response.data)
      })
  })
}

export const tokenConfig = getState => {
  // Get token from localStorage
  const token = getState().auth.token

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
  if (token) {
    config.headers["Authorization"] = "Bearer " + token
  }

  return config
}
